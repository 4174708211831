import React from "react";
import "./contact.css";

const Contact = () => {
    return (
        <main className="mainGrid3">
            <p>In progress...</p>
        </main>
    )
};

export default Contact;