// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../components/images/image2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainGrid2{
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    flex-grow: 1;
}

.expSection1{
    height: 100%;
}

.expImage{
    height: 90%;
    width: 90%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    margin-left: 5.1vw;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;

    
}

.expTittle{
    margin-top: auto;
    margin-bottom: 1vw;
    margin-left: 2vw;
    font-family: 'Anton', sans-serif;
    font-weight: 400;
    font-size: 8vw;
    color: white;
}

.expSection2{

}`, "",{"version":3,"sources":["webpack://./src/pages/exp/exp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,yDAA2D;IAC3D,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;;;AAG1B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,gCAAgC;IAChC,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;;AAEA","sourcesContent":[".mainGrid2{\n    display: grid;\n    grid-template-columns: 1.1fr 1fr;\n    flex-grow: 1;\n}\n\n.expSection1{\n    height: 100%;\n}\n\n.expImage{\n    height: 90%;\n    width: 90%;\n    background-image: url('../../components/images/image2.png');\n    background-size: cover;\n    margin-left: 5.1vw;\n    border-radius: 2vw;\n    display: flex;\n    flex-direction: column;\n\n    \n}\n\n.expTittle{\n    margin-top: auto;\n    margin-bottom: 1vw;\n    margin-left: 2vw;\n    font-family: 'Anton', sans-serif;\n    font-weight: 400;\n    font-size: 8vw;\n    color: white;\n}\n\n.expSection2{\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
