import "./skills.css";

const Skills = () => {
    return (
        <main className="mainGrid5">
            <p>In progress...</p>
        </main>
    )
};

export default Skills;