// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerCube{
    height: auto;
    display: flex;
    justify-content: center;
}
.tittle {
    font-family: 'Anton', sans-serif;
    font-weight: 400;
    font-size: 21.5vw;
}

#tittleSpace{
    letter-spacing: 0.2vw;
}

.subtittle {
    font-weight: 400;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-family: 'Anton', sans-serif;
    margin-bottom: 1.6vw;
}

#lastName1 {
    font-size: 6.6vw;

}

#lastName2 {
    font-size: 4.5vw;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,yBAAyB;IACzB,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;;AAEpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".headerCube{\n    height: auto;\n    display: flex;\n    justify-content: center;\n}\n.tittle {\n    font-family: 'Anton', sans-serif;\n    font-weight: 400;\n    font-size: 21.5vw;\n}\n\n#tittleSpace{\n    letter-spacing: 0.2vw;\n}\n\n.subtittle {\n    font-weight: 400;\n    writing-mode: vertical-rl;\n    transform: rotate(180deg);\n    font-family: 'Anton', sans-serif;\n    margin-bottom: 1.6vw;\n}\n\n#lastName1 {\n    font-size: 6.6vw;\n\n}\n\n#lastName2 {\n    font-size: 4.5vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
