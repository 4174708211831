// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainGrid5{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-weight: 700;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 7vw;
}`, "",{"version":3,"sources":["webpack://./src/pages/skills/skills.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,qCAAqC;IACrC,cAAc;AAClB","sourcesContent":[".mainGrid5{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-grow: 1;\n    font-weight: 700;\n    color: white;\n    font-family: 'Montserrat', sans-serif;\n    font-size: 7vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
