import React from "react";
import "./projects.css";

const Projects = () => {
    return (
        <main className="mainGrid4">
            <p>In progress...</p>
        </main>
    )
};

export default Projects;