import React from "react";
import "./exp.css";

const Exp = () => {
    return (
        <main className="mainGrid2">
            <section className="expSection1">
                <div className="expImage">
                    <p className='expTittle'>EXPERIENCE</p>
                </div>
            </section>
            <section className="expSection2">

            </section>
        </main>
    )
};

export default Exp;